import React from 'react';

const Analytics = () => {
  return (
    <div>
      <iframe
        title="Calytics 2"
        width="1160"
        height="650"
        src="https://app.powerbi.com/view?r=eyJrIjoiMjdkNTFlMTAtZGI4MC00ZWY3LWE0MmQtMzY2YTUyMTE4ZDQ5IiwidCI6ImM1OWQ2MDY4LTAwYWMtNGEwZC1iMTIwLWJlMGUwODliMmZjNiJ9&pageName=ReportSection39abe418e09ee778497b"
        frameborder="0"
        allowFullScreen="true"
      ></iframe>
    </div>
  );
};

export default Analytics;
