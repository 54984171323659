/* eslint-disable linebreak-style */
/* eslint-disable import/prefer-default-export */
export const STOREUSER = 'STOREUSER';
export const GETUSERCOMPONENTLIST = 'GETUSERCOMPONENTLIST';
export const GETUSERPLAYLISTLIST = 'GETUSERPLAYLISTLIST';
export const GETUSERSCHEDULELIST = 'GETUSERSCHEDULELIST';
export const GETUSERMEDIALIST = 'GETUSERMEDIALIST';
export const SAVEPLAYLIST = 'SAVEPLAYLIST';
export const SAVESCHEDULE = 'SAVESCHEDULE';
export const REMOVEUSER = 'REMOVEUSER';
export const SAVEMEDIA = 'SAVEMEDIA';
export const SAVEMONITOR = 'SAVEMONITOR';
export const DELETECOMPONENTLIST = 'DELETECOMPONENTLIST';
export const GETUSERMONITORDETAILS = 'GETUSERMONITORDETAILS';
export const GETUSERPLAYLISTDETAILS = 'GETUSERPLAYLISTDETAILS';
export const GETUSERSCHEDULEDETAILS = 'GETUSERSCHEDULEDETAILS';
export const GETUSERMEDIADETAILS = 'GETUSERMEDIADETAILS';
